<template>
	<div class="contant">
		<img src="../../assets/img/pc/logo.png" class="img" alt="">
		<div class="loginCon">
			<div class="leCon">
				<div class="formCon">
					<img src="../../assets/img/pc/hedImg.png" class="hedImg" alt="">
					<el-input v-model="phone" maxlength="11" placeholder="请输入手机号" @keyup.enter="login">
						<i slot="prefix" class="el-input__icon el-icon-mobile"></i>
					</el-input>
					<div class="btnCon" @click="login">
						<img class="icon" src="@/assets/img/pc/kt.png" alt="">
						<span class="txt">进入课堂</span>
					</div>
					<van-checkbox class="login_checkbox" v-model="isRemember" shape="square" icon-size="16px"
						checked-color="#B5802B">记住账号</van-checkbox>
				</div>
			</div>
			<div class="riCon">
				<img src="../../assets/img/pc/gyxs.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				phone: "",
				isRemember: false
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				let user = localStorage.getItem('user');
				if (!user) return;
				user = JSON.parse(user);
				if (!user.isRemember) return;
				this.isRemember = user.isRemember;
				this.phone = user.phone;
			},
			async login() {
				if (!this.phone) {
					this.$message.error("请输入手机号");
					return;
				}
				this.$toast.loading({
					message: '登录中...',
					forbidClick: true,
				});
				const res = await this.$api.jntsLogin({
					"phone": this.phone
				});
				const data = res.data;
				localStorage.setItem("webToken", data.token);

				localStorage.setItem("user", JSON.stringify({
					isRemember: this.isRemember,
					phone: data.phone,
					name: data.name,
					user_id: data.user_id
				}));

				setTimeout(() => {
					this.$toast.clear();
					this.$router.push('/pc/home');
				}, 500)
			}
		}
	}
</script>

<style scoped lang="less">
	.contant {
		width: 100%;
		min-width: 1200px;
		min-height: 100vh;
		background: url('../../assets/img/pc/loginBg.png') no-repeat;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.img {
			width: 326px;
			margin-bottom: 40px;
			margin-top: 70px;
			display: block;
		}

		.loginCon {
			width: 1200px;
			height: 600px;
			background: url('../../assets/img/pc/loginBg2.png') no-repeat;
			background-size: 100% 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.leCon {
				width: 480px;
				height: 282px;

				.formCon {
					width: 345px;
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					align-items: center;

					.hedImg {
						width: 79px;
						height: 79px;
						display: block;
						margin-bottom: 40px;
					}

					/deep/.el-input__inner {
						border: 2px solid #B5802B;
						border-radius: 16px;
					}

					/deep/.el-input__icon {
						font-size: 25px;
						color: #B5802B;
					}

					.btnCon {
						width: 345px;
						height: 57px;
						background: #B5802B;
						border: 3px solid #B5802B;
						border-radius: 23px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: 8px;
						margin-bottom: 20px;
						cursor: pointer;

						.icon {
							width: 29px;
						}

						.txt {
							font-size: 28px;
							color: #fff;
							margin-left: 8px;
						}
					}
				}

			}

			.riCon {
				width: 720px;
				height: 282px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-left: 2px solid #BABABB;

				img {
					width: 570px;
				}
			}
		}
	}
</style>